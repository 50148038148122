import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import RatingsSummary from '../components/RatingsSummary';
import Reviews from '../components/Reviews';

const EmptyStateContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    textAlign: 'center',
    minHeight: '200px',
    background: 'linear-gradient(180deg, rgba(255,255,255,0.8) 0%, rgba(247,247,247,0.4) 100%)',
    borderRadius: '12px',
    margin: theme.spacing(0, 0, 2),
    transition: 'all 0.3s ease-in-out',
    border: '1px solid rgba(0,0,0,0.05)',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(0,0,0,0.03)'
    }
}));

const EmptyStateIcon = styled(Box)(({ theme }) => ({
    fontSize: '2.5rem',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    opacity: 0.9,
    animation: 'float 3s ease-in-out infinite',
    '@keyframes float': {
        '0%': {
            transform: 'translateY(0px)'
        },
        '50%': {
            transform: 'translateY(-6px)'
        },
        '100%': {
            transform: 'translateY(0px)'
        }
    }
}));

const EmptyStateTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    color: '#1a1a1a',
    fontSize: '1.1rem'
}));

const EmptyStateDescription = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    maxWidth: '320px',
    margin: '0 auto',
    fontSize: '0.9rem',
    lineHeight: 1.4
}));

const NoReviewsMessage = () => (
    <EmptyStateContainer>
        <EmptyStateIcon>⭐️</EmptyStateIcon>
        <EmptyStateTitle>
            Отзывов пока нет
        </EmptyStateTitle>
        <EmptyStateDescription>
            Станьте первым, кто оставит отзыв о работе продавца
        </EmptyStateDescription>
    </EmptyStateContainer>
);

const LoadingMessage = () => (
    <EmptyStateContainer>
        <EmptyStateIcon>⌛</EmptyStateIcon>
        <EmptyStateTitle>
            Загрузка отзывов...
        </EmptyStateTitle>
        <EmptyStateDescription>
            Пожалуйста, подождите несколько секунд
        </EmptyStateDescription>
    </EmptyStateContainer>
);

const ReviewsPage = ({ cardInfo }) => {
    console.log('ReviewsPage cardInfo:', cardInfo);

    if (!cardInfo) {
        return <LoadingMessage />;
    }

    const { reviewCount = 0, gradeMap = {}, code, reviews = [] } = cardInfo;

    // Проверяем, что gradeMap содержит все необходимые ключи
    const defaultGradeMap = {
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        ...gradeMap
    };

    return (
        <Box sx={styles.container}>
            <RatingsSummary 
                reviewCount={reviewCount} 
                gradeMap={defaultGradeMap} 
                code={code}
            />
            {reviewCount === 0 ? (
                <NoReviewsMessage />
            ) : (
                <Reviews reviews={reviews} />
            )}
        </Box>
    );
};

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 0
    }
};

export default ReviewsPage;