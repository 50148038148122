// src/hooks/useProducts.jsx
import { useState, useEffect } from 'react';
import apiService from '../api/api_service';

export const useProducts = (code) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            console.log('Starting to fetch products for code:', code);
            if (code) {
                try {
                    setLoading(true);
                    console.log('Making API request for products...');
                    const response = await apiService.getCardProducts(code);
                    console.log('API Response received:', response);
                    
                    if (Array.isArray(response)) {
                        console.log('Valid products array received, length:', response.length);
                        setProducts(response);
                        setError(null);
                    } else {
                        console.error('Invalid response format:', response);
                        setError('Получены некорректные данные');
                        setProducts([]);
                    }
                } catch (error) {
                    console.error('Error details:', error);
                    setError('Ошибка при получении продуктов');
                    setProducts([]);
                } finally {
                    console.log('Finishing products fetch, setting loading to false');
                    setLoading(false);
                }
            } else {
                console.error('No code provided');
                setError('Код не определен');
                setLoading(false);
            }
        };
        fetchProducts();
    }, [code]);

    return { products, loading, error };
};
